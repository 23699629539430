/** @jsx jsx */
import { Styled, jsx } from 'theme-ui'
import { Link, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

import Layout from '../components/layout'
import Seo from '../components/seo'

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const { image } = data

  return (
    <Layout location={location} title={siteTitle} minimal>
      <Seo title="404: Not Found" />
      <Styled.div
        sx={{
          width: '100%',
          height: '100vh',
          backgroundPosition: 'bottom center',
          backgroundRepeat: 'repeat-y',
          backgroundSize: 'cover'
        }}
      >
        <BackgroundImage Tag="section" fluid={image.childImageSharp.fluid} backgroundColor={`#040e18`}>
          <div
            style={{
              position: 'relative',
              height: '100vh',
              margin: '0 auto',
              textAlign: 'center',
              maxWidth: '80%',
              width: '480px',
              minWidth: '80%',
              paddingTop: '40vh'
            }}
          >
            <Styled.h1 sx={{ fontSize: ['175%', '300%', '350%'] }}>404: Not Found</Styled.h1>
            <Styled.p>You just hit a route that doesn&#39;t exist... the sadness.</Styled.p>
            <Styled.p>
              {' '}
              <Link to="/" sx={{ color: 'text', fontSize: '150%' }}>
                return to home
              </Link>
            </Styled.p>
          </div>
        </BackgroundImage>
      </Styled.div>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    image: file(relativePath: { eq: "page-404.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
